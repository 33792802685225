import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CImageMapper from '../../editor/components/CImageMapper/CImageMapper';
import PropTypes from 'prop-types';
import { isSuperAdmin } from '../../../modules/common/utils';
import styles from './CameraImageLots.module.css';
import Private from '../../common/components/Private/Private';

const BOX_COLOR = 'rgb(252, 251, 0)';
const BOX_COLOR_PERSON = 'rgb(74,187,252)';

const getPlaceColor = freeLots => {
  if (freeLots === -1) {
    return 'rgba(217,217,217, 0.9)';
  } else if (freeLots > 0) {
    return 'rgba(124, 252, 0, 0.35)';
  } else {
    return 'rgba(255, 0, 0, 0.35)';
  }
};

const CameraImageLots = ({ imageURL, parkingLots, lastDetectionBoxes, allowedIntersectionsVisible }) => {
  const parkingLotsMapped = parkingLots.map(({ freeLots = 0, capacity = 0, ...pl }) => ({
    ...pl,
    color: getPlaceColor(freeLots)
  }));

  const [procLog, setProcLog] = useState(null);
  const [procLog2, setProcLog2] = useState(null);
  const [showLots, setShowLots] = useState(true);
  const [showBoxes, setShowBoxes] = useState(true);
  const [selectedLot, setSelectedLot] = useState(null);
  const [showAllowedIntersections, setShowAllowedIntersections] = useState(false);
  const [intersectionIndex, setIntersectionIndex] = useState(0);

  const handleParkingLotClick = lot => {
    console.log(lot);
    if (!showAllowedIntersections) {
      if (lot.procLog) {
        setProcLog(lot.procLog);
        setProcLog2(lot.procLog2);
      }
    } else if (lot.allowedIntersections.length) {
      setIntersectionIndex(0);
      setSelectedLot(lot);
    } else {
      setIntersectionIndex(0);
      setSelectedLot(null);
    }
  };

  const handleShowAllowedIntersectionsSwitch = state => {
    if (!state) {
      setIntersectionIndex(0);
      setSelectedLot(null);
    }

    setShowAllowedIntersections(state);
  };

  const handlePrev = () => {
    if (intersectionIndex > 0) {
      setIntersectionIndex(intersectionIndex - 1);
    }
  };

  const handleNext = () => {
    if (intersectionIndex < selectedLot.allowedIntersections.length - 1) {
      setIntersectionIndex(intersectionIndex + 1);
    }
  };

  const handleClose = () => setProcLog(null);

  const draw = ctx => {
    if (showBoxes) {
      ctx.lineWidth = 2;
      ctx.font = 'bold 16px Arial';

      lastDetectionBoxes.forEach(({ box: { x, y, w, h }, accuracy, type }) => {
        ctx.fillStyle = type === 'car' ? BOX_COLOR : BOX_COLOR_PERSON;
        ctx.strokeStyle = type === 'car' ? BOX_COLOR : BOX_COLOR_PERSON;
        ctx.beginPath();
        ctx.rect(x, y, w, h);
        ctx.stroke();
        ctx.closePath();
        ctx.fillText(accuracy.toFixed(2), x, y - 10);
      });
    }

    if (selectedLot) {
      const intersection = selectedLot.allowedIntersections[intersectionIndex];
      const groupId = intersection.groupId.toString().slice(-6);

      ctx.lineWidth = 2;
      ctx.fillStyle = 'rgb(0,252,175)';
      ctx.strokeStyle = 'rgb(0,252,175)';

      intersection.rectangles.forEach(r => {
        ctx.beginPath();
        ctx.rect(r.x, r.y, r.w, r.h);
        ctx.stroke();
        ctx.closePath();
        ctx.fillText(`${groupId}:${r.recId}`, r.x, r.y - 10);
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <CImageMapper
        readOnly
        drawLots={showLots}
        imageURL={imageURL}
        parkingLots={parkingLotsMapped}
        drawAdditional={draw}
        onParkingLotClick={handleParkingLotClick}
      />
      <Dialog
        open={!!procLog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Географические координы
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{procLog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ок
          </Button>
        </DialogActions>
      </Dialog>
      <div className={styles.toggleContainer}>
        {showLots ? (
          <i className={`${styles.toggle} fas fa-eye`} onClick={() => setShowLots(false)} />
        ) : (
          <i className={`${styles.toggle} fas fas fa-eye-slash`} onClick={() => setShowLots(true)} />
        )}
        {showBoxes ? (
          <i className={`${styles.toggle} fas fa-eye`} onClick={() => setShowBoxes(false)} />
        ) : (
          <i className={`${styles.toggle} fas fas fa-eye-slash`} onClick={() => setShowBoxes(true)} />
        )}
        {/* <Private>
          <div>
            {showAllowedIntersections ? (
              <i
                className={`${styles.toggle} fas fa-eye`}
                onClick={() => handleShowAllowedIntersectionsSwitch(false)}
              />
            ) : (
              <i
                className={`${styles.toggle} fas fas fa-eye-slash`}
                onClick={() => handleShowAllowedIntersectionsSwitch(true)}
              />
            )}
            {showAllowedIntersections && selectedLot && (
              <div>
                <button onClick={handlePrev}>Prev.</button>
                {intersectionIndex}
                <button onClick={handleNext}>Next</button>
              </div>
            )}
          </div>
        </Private> */}
      </div>
    </div>
  );
};

CameraImageLots.propTypes = {
  imageURL: PropTypes.string.isRequired,
  lastDetectionBoxes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      accuracy: PropTypes.number.isRequired,
      box: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired
      }).isRequired
    })
  ),
  parkingLots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      cameraPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};
CameraImageLots.defaultProps = {
  lastDetectionBoxes: []
};

export default CameraImageLots;
