import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './YMapEditor.module.css';
import classnames from 'classnames';
import withYMap from '../YMap/YMap';

const options = { fillColor: 'rgba(255,120,0,0.5)', strokeColor: 'rgba(255,120,0,0.84)', strokeWidth: 2, strokeOpacity: 0.8, draggable: true };
const optionsDisabled = { fillColor: 'rgba(131,131,131,0.5)', strokeColor: 'rgba(80,80,80,0.96)', strokeWidth: 2, strokeOpacity: 0.8, draggable: false };

class YMapEditor extends Component {

  state = {
    clickPolygonMode: undefined
  }

  componentDidMount() {
    const {
      ymaps,
      center: {
        lat,
        lng
      },
      onMapPolygonAdd,
      onMapPolygonEdit
    } = this.props;

    this.map = new ymaps.Map("YMapEditor_map", {
      center: [lat, lng],
      zoom: 19
    });

    this.map.geoObjects.add(new ymaps.Placemark([lat, lng], {}));

    this.map.events.add('click', (e) => {
      if (this.state.clickPolygonMode) {
        const [lat, lng] = e.get('coords');
        const obj = new ymaps.Circle([[lat, lng], 1], {}, options);
        this.map.geoObjects.add(obj);

        obj.events.add('dragend', (e) => {
          const objects = [];
          this.map.geoObjects.each(o => objects.push(o));

          const index = objects.findIndex(o => o === obj);
          const parkingLotId = this.props.parkingLots[index].parkingLotId;
          const [lat, lng] = obj.geometry.getCoordinates();

          onMapPolygonEdit(parkingLotId, { lat, lng });
        });

        onMapPolygonAdd([{ lat, lng }]);
      }
      });

    this.props.parkingLots.filter(p => p.mapPolygon.length > 0).forEach(({ mapPolygon }) => {
      const obj = new ymaps.Circle([[mapPolygon[0].lat, mapPolygon[0].lng], 1], {}, options);
      this.map.geoObjects.add(obj);

      obj.events.add('dragend', (e) => {
        const objects = [];
        this.map.geoObjects.each(o => objects.push(o));

        const index = objects.findIndex(o => o === obj);
        const parkingLotId = this.props.parkingLots[index].parkingLotId;
        const [lat, lng] = obj.geometry.getCoordinates();

        onMapPolygonEdit(parkingLotId, { lat, lng });
      });
    });

    this.props.nearbyParkingLots.filter(p => p.mapPolygon.length > 0).forEach(({ mapPolygon }) => {
      const obj = new ymaps.Circle([[mapPolygon[0].lat, mapPolygon[0].lng], 1], {}, optionsDisabled);
      this.map.geoObjects.add(obj);
    });
  }



  handleClickPolygonModeChange = () => {
    this.setState({
      clickPolygonMode: !this.state.clickPolygonMode
    });
  }

  drawParkingLots = (parkingLots) => {
    const that = this;
    const {
      onMapPolygonEdit,
      DG
    } = this.props;

    // clear
    Object.keys(this.editableLayers._layers)
      .forEach(k => this.editableLayers.removeLayer(this.editableLayers._layers[k]));

    parkingLots.filter(lot => lot.mapPolygon.length > 0).forEach(({ mapPolygon }) => {
      const obj = mapPolygon.length === 1
        ? DG.circle([mapPolygon[0].lat, mapPolygon[0].lng], { ...options }).addTo(this.editableLayers)
        : DG.polygon(mapPolygon.map(p => [p.lat, p.lng]), { ...options }).addTo(this.editableLayers);

      obj.on('dragend', () => {
        const index = Object.keys(that.editableLayers._layers).findIndex(l => l == obj._leaflet_id);
        const parkingLotId = that.props.parkingLots[index].parkingLotId;

        onMapPolygonEdit(parkingLotId, obj.getLatLngs ? obj.getLatLngs()[0] : [obj.getLatLng()]);
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      parkingLots
    } = this.props;

    if (parkingLots.length !== prevProps.parkingLots.length) {
      this.drawParkingLots(parkingLots);
    }
  }

  render() {
    const {
      clickPolygonMode
    } = this.state;
    return (
      <div className={styles.root}>
        <div className={styles.ctrls}>
          <div className={classnames(styles.ctrl, { [styles.selected]: !!clickPolygonMode})}
               title="Создать место"
               onClick={this.handleClickPolygonModeChange}
          >
            <i className="fas fa-circle" />
          </div>
        </div>
        <div id="YMapEditor_map" className={styles.map}>
        </div>
      </div>
    );
  }
}

YMapEditor.propTypes = {
  nearbyParkingLots: PropTypes.arrayOf(PropTypes.shape({
    parkingLotId: PropTypes.string.isRequired,
    mapPolygon: PropTypes.arrayOf(PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    })).isRequired
  })).isRequired,
  parkingLots: PropTypes.arrayOf(PropTypes.shape({
    parkingLotId: PropTypes.string.isRequired,
    mapPolygon: PropTypes.arrayOf(PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    })).isRequired
  })).isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  onMapPolygonAdd: PropTypes.func.isRequired,
  onMapPolygonEdit: PropTypes.func.isRequired
};
YMapEditor.defaultProps = {};

export default withYMap(YMapEditor);
