/* global ymaps */
import React, { Component } from 'react';

export default function withYMap(Child) {
  return class YMap extends Component {
    state = {
      loaded: false
    }

    componentDidMount() {
      ymaps.ready(() => {
        this.setState({
          loaded: true
        });
      });
    }

    render() {
      const {
        loaded
      } = this.state;

      return (
        <div>
          {
            loaded && (
              <Child ymaps={ymaps} { ...this.props } />
            )
          }
        </div>
      );
    }
  }
}
