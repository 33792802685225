import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce, isEqual } from 'lodash';

import withMap from '../Map/Map';
import markerGreen from './marker-green.png';
import markerYellow from './marker-yellowe.png';
import styles from './CamerasMap.module.css';

class CamerasMap extends Component {
  handleViewChange = debounce(() => {
    const { onViewChanged } = this.props;

    const bounds = this.map.getBounds();
    const zoom = this.map.getZoom();

    onViewChanged({
      bbox: {
        westLng: bounds.getWest(),
        southLat: bounds.getSouth(),
        eastLng: bounds.getEast(),
        northLat: bounds.getNorth()
      },
      zoom
    });
  }, 2000).bind(this);

  componentDidMount() {
    const { DG, onViewChanged } = this.props;

    this.map = DG.map('map', {
      center: DG.latLng(...[55.737899, 37.641737]),
      zoom: 12
    });

    const bounds = this.map.getBounds();
    const zoom = this.map.getZoom();

    this.layerGroup = DG.markerClusterGroup({ disableClusteringAtZoom: 17 }).addTo(this.map);
    //this.layerGroup = DG.layerGroup().addTo(this.map);
    this.map.on('moveend', this.handleViewChange);

    this.addCamerasToMap();

    onViewChanged({
      bbox: {
        westLng: bounds.getWest(),
        southLat: bounds.getSouth(),
        eastLng: bounds.getEast(),
        northLat: bounds.getNorth()
      },
      zoom
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.cameras, prevProps.cameras)) {
      this.layerGroup.clearLayers();
      this.addCamerasToMap();
    }
  }

  addCamerasToMap = () => {
    const { cameras, onCameraSelect, DG } = this.props;

    const getIcon = (camera, large = false) => {
      let iconUrl;

      if (camera.isAutoUpdated) {
        iconUrl = markerYellow;
      } else if (camera.handCorrected) {
        iconUrl = markerGreen;
      } else if (camera.marked) {
        iconUrl = 'https://maps.api.2gis.ru/2.0/img/DGCustomization__markerHover.png';
      } else {
        iconUrl = 'https://maps.api.2gis.ru/2.0/img/DGCustomization__marker.png';
      }

      return DG.icon({
        iconUrl,
        iconSize: [large ? 22 : 11, large ? 34 : 17]
      });
    };

    DG.geoJSON(cameras, {
      onEachFeature: (feature, layer) => {
        const that = this;
        layer.on({
          click: function() {
            if (that.marker) {
              that.marker.setIcon(getIcon(feature.properties.camera));
            }

            that.marker = this;
            that.marker.setIcon(getIcon(feature.properties.camera, true));

            onCameraSelect(feature.properties.camera);
          }
        });
      },
      pointToLayer: (feature, latlng) => {
        return DG.marker(latlng, { icon: getIcon(feature.properties.camera) });
      }
    }).addTo(this.layerGroup);

    // cameras.forEach((camera) => {
    //   let iconUrl;
    //
    //   if (camera.isAutoUpdated) {
    //     iconUrl = markerYellow;
    //   } else if (camera.handCorrected) {
    //     iconUrl = markerGreen;
    //   } else if (camera.marked) {
    //     iconUrl = 'https://maps.api.2gis.ru/2.0/img/DGCustomization__markerHover.png';
    //   } else {
    //     iconUrl = 'https://maps.api.2gis.ru/2.0/img/DGCustomization__marker.png';
    //   }
    //
    //   const icon = DG.icon({
    //     iconUrl,
    //     iconSize: [11, 17]
    //   });
    //
    //   DG.marker([camera.location.coordinates[1], camera.location.coordinates[0]], { icon, cameraId: camera._id })
    //     .addTo(this.layerGroup).on('click', ({ target: { options: { cameraId } } }) => onCameraSelect(cameraId));
    // });
  };

  render() {
    return (
      <div className={styles.root}>
        <div id="map" className={styles.map} />
      </div>
    );
  }
}

CamerasMap.propTypes = {
  cameras: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      location: PropTypes.shape({
        type: PropTypes.oneOf(['Point']).isRequired,
        coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
      }).isRequired
    })
  ).isRequired,
  onCameraSelect: PropTypes.func.isRequired,
  onViewChanged: PropTypes.func.isRequired
};
CamerasMap.defaultProps = {};

export default withMap(
  CamerasMap,
  'https://2gis.github.io/mapsapi/vendors/Leaflet.markerCluster/leaflet.markercluster-src.js'
);
