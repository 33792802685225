const PRELOADER_SHOW = 'PRELOADER_SHOW';
const PRELOADER_HIDE = 'PRELOADER_HIDE';

export function preloaderShow() {
  return ({
    type: PRELOADER_SHOW
  });
}

export function preloaderHide() {
  return ({
    type: PRELOADER_HIDE
  });
}

export default function reducer(state = { stack: 0 }, action = {}) {
  switch (action.type) {
    case PRELOADER_SHOW:
      return {
        ...state,
        stack: state.stack + 1
      };
    case PRELOADER_HIDE:
      return {
        ...state,
        stack: state.stack - 1
      };
    default:
      return state;
  }
}

