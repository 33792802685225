import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CImageMapper from '../CImageMapper/CImageMapper';
import Portal from '../../Portal';
import GMapEditor from '../GMapEditor/GMapEditor';
import YMapEditor from '../YMapEditor/YMapEditor';

class ParkingLotsEditor extends Component {
  state = {
    useYandex: false
  };

  handleChange = () => {
    this.setState({
      useYandex: !this.state.useYandex
    });
  };

  render() {
    const {
      cameraImageURL,
      parkingLots,
      nearbyParkingLots,
      cameraLocation,
      onCameraPolygonCreate,
      onCameraPolygonPointAdd,
      onCameraPolygonPointChange,
      onCameraPolygonDelete,
      onMapPolygonAdd,
      onMapPolygonEdit,
      onAttrChange,
      onImageSelect,
      imgCount,
      imgSelected,
      colors
    } = this.props;

    return (
      <div>
        <CImageMapper
          colors={colors}
          imageURL={cameraImageURL}
          imgCount={imgCount}
          imgSelected={imgSelected}
          onImageSelect={onImageSelect}
          parkingLots={parkingLots}
          onCameraPolygonCreate={onCameraPolygonCreate}
          onCameraPolygonPointAdd={onCameraPolygonPointAdd}
          onCameraPolygonPointChange={onCameraPolygonPointChange}
          onCameraPolygonDelete={onCameraPolygonDelete}
          onAttrChange={onAttrChange}
        />
        <Portal container={this.props.mapEditorContainer}>
          <div>
            {/* <div>
              <input type="checkbox" id="useYanded" checked={this.state.useYandex} onClick={this.handleChange} />
              <label htmlFor="useYanded">Yandex</label>
            </div> */}
            {this.state.useYandex ? (
              <YMapEditor
                center={cameraLocation}
                onMapPolygonAdd={onMapPolygonAdd}
                onMapPolygonEdit={onMapPolygonEdit}
                parkingLots={parkingLots}
                nearbyParkingLots={nearbyParkingLots}
              />
            ) : (
              <GMapEditor
                colors={colors}
                center={cameraLocation}
                onMapPolygonAdd={onMapPolygonAdd}
                onMapPolygonEdit={onMapPolygonEdit}
                parkingLots={parkingLots}
                nearbyParkingLots={nearbyParkingLots}
              />
            )}
          </div>
        </Portal>
      </div>
    );
  }
}

ParkingLotsEditor.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  cameraImageURL: PropTypes.string.isRequired,
  imgCount: PropTypes.number,
  imgSelected: PropTypes.number,
  parkingLots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      cameraPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        })
      ).isRequired,
      mapPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  nearbyParkingLots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      cameraPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        })
      ).isRequired,
      mapPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  cameraLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  onCameraPolygonCreate: PropTypes.func.isRequired,
  onCameraPolygonPointAdd: PropTypes.func.isRequired,
  onCameraPolygonPointChange: PropTypes.func.isRequired,
  onCameraPolygonDelete: PropTypes.func.isRequired,
  onMapPolygonAdd: PropTypes.func.isRequired,
  onMapPolygonEdit: PropTypes.func.isRequired,
  onAttrChange: PropTypes.func.isRequired,
  onImageSelect: PropTypes.func,
  mapEditorContainer: PropTypes.instanceOf(Element).isRequired
};
ParkingLotsEditor.defaultProps = {};

export default ParkingLotsEditor;
