import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import Preloader from './modules/common/components/Preloader/Preloader';
import PrivateRoute from './modules/auth/PrivateRoute';
import Callback from './modules/auth/Callback';
import AppBar from './modules/app/components/AppBar/AppBar';
import ParkingLotsEditorView from './modules/editor/components/ParkingLotsEditorView/ParkingLotsEditorView';
import { authenticated, unAuthenticated } from './modules/auth/redux/auth';

import DebugView from './modules/debug/DebugView/DebugView';
import './App.css';
import LoginView from './modules/auth/LoginView/LoginView';

class App extends React.Component {
  componentDidMount() {
    const {
      auth,
      dispatch,
      location: { pathname },
      authenticated: isAuthenticated
    } = this.props;

    // if (!pathname.endsWith('login-callback')) {
    //   if (!isAuthenticated) {
    //     auth
    //       .renewSession()
    //       .then(({ idToken, expiresAt }) => {
    //         dispatch(authenticated(idToken, expiresAt));
    //       })
    //       .catch(() => {
    //         dispatch(unAuthenticated());
    //         auth.login();
    //       });
    //   }
    // }
  }

  render() {
    const { auth } = this.props;

    return (
      <div className="App">
        <Preloader />
        <AppBar />
        <PrivateRoute path="/" exact component={DebugView} />
        <Route path="/login" exact component={LoginView} />
        <PrivateRoute path="/hidden-super-top-secret-page" component={ParkingLotsEditorView} />
      </div>
    );
  }
}

App.propTypes = {};

const mapStateToProps = state => {
  return {
    authenticated: true //state.auth.authenticated && new Date().getTime() < state.auth.expiresAt
  };
};

export default withRouter(connect(mapStateToProps)(App));
