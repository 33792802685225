import axios from '../net/axios';
import store from '../redux/index';

export async function getCameras() {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return (await axios.get(`/admin/cameras`, {
    headers: { Authorization: `Bearer ${token}` }
  })).data;
}

export async function getCamera(cameraId) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return (await axios.get(`/admin/cameras/get-camera?cameraId=${cameraId}`, {
    headers: { Authorization: `Bearer ${token}` }
  })).data;
}

export async function getParkingLots(cameraId) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return (await axios.get(`/admin/cameras/parking-lots?cameraId=${cameraId}`, {
    headers: { Authorization: `Bearer ${token}` }
  })).data;
}

export async function getParkingLotsInArea(cameraId) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return (await axios.get(`/admin/parking-lots?cameraId=${cameraId}`, {
    headers: { Authorization: `Bearer ${token}` }
  })).data;
}

export async function getCameraShotUrls(cameraId, last = false) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return (await axios.get(`/admin/cameras/shotUrl?cameraId=${cameraId}&last=${last}`, {
    headers: { Authorization: `Bearer ${token}` }
  })).data;
}

export async function cameraRequestUpdate(key) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return await axios.post(`/admin/cameras/request-update`, key, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export async function saveParkingLots(cameraId, parkingLots) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  const parkingLotsCreated = (await axios.post(`/admin/cameras/${cameraId}/create-update-parking-lots`, parkingLots, {
    headers: { Authorization: `Bearer ${token}` }
  })).data;

  console.log(parkingLotsCreated);
  console.log(parkingLots);

  return parkingLotsCreated;
}

export async function saveCameraConstants(cameraId, constants) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  await axios.post(`/admin/cameras/${cameraId}/update-constants`, constants, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export async function queueAnalyzer(cameraId) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  await axios.head(`/admin/cameras/${cameraId}/stats-analyzer`, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export async function invalidateCamera(cameraId) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  await axios.head(`/admin/cameras/${cameraId}/invalidate`, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function saveShot(key) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return axios.post(`/admin/cameras/save-shot`, key, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function switchSaveShotState(cameraId, state) {
  const { token } = JSON.parse(localStorage.getItem('token'));

  return axios.post(
    `/admin/cameras/save-shot-switch-state?cameraId=${cameraId}`,
    { state },
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
}
