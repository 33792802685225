import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './Preloader.module.css'

class Preloader extends PureComponent {
  render() {
    if (this.props.visible) {
      return (
        <div className={styles.wrapper}>
          <CircularProgress size={80} />
        </div>
      );
    }

    return null;
  }
}

Preloader.propTypes = {
  visible: PropTypes.bool.isRequired
};
Preloader.defaultProps = {};

const mapStateToProps = (state, { forceShow }) => {
  return {
    visible: state.preloader.stack > 0 || forceShow
  }
};

export default connect(mapStateToProps)(Preloader);
