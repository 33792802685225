import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return <Route {...rest} render={props => authenticated && <Component {...props} />} />;
};

const mapStateToProps = state => {
  return {
    authenticated: true //state.auth.authenticated && new Date().getTime() < state.auth.expiresAt
  };
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
