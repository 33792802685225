import MaterialAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { isTester } from '../../../common/utils';

const styles = theme => ({
  root: {
    width: '100%'
  },
  margin: {
    margin: theme.spacing.unit
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class AppBar extends React.Component {
  state = {
    allowedIntersectionsVisible: false
  };

  componentDidMount() {
    // const id = setInterval(() => {
    //   const profile = auth.getProfile();
    //   if (profile) {
    //     clearInterval(id);
    //   }
    // }, 100);
  }

  handleDebugClick = () => {
    const { history } = this.props;

    history.push('/');
  };

  handleEditorClick = () => {
    const { history } = this.props;

    history.push('/edit');
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <MaterialAppBar position="static">
          <Toolbar>
            {!isTester() && (
              <Button className={classes.margin} color="inherit" onClick={this.handleEditorClick}>
                Редактор
              </Button>
            )}
            <Button color="inherit" onClick={this.handleDebugClick}>
              Просмотр
            </Button>
          </Toolbar>
        </MaterialAppBar>
      </div>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(AppBar));
