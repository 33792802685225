import { createStore, applyMiddleware, combineReducers } from 'redux';
import loggerMiddleware from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import cameras from '../editor/redux/cameras';
import preloader from '../common/redux/preloader';
import auth from '../auth/redux/auth';

const reducer = combineReducers({
  cameras,
  preloader,
  auth
});

export default createStore(
  reducer,
  applyMiddleware(loggerMiddleware, thunkMiddleware)
);

