import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { preloaderHide, preloaderShow } from '../../redux/preloader';

const cache = {};

export default function ImagePreloader(Child) {
  class ImagePreloader extends Component {

    static propTypes = {
      imageURL: PropTypes.string.isRequired
    };

    state = {
      loaded: false,
      image: undefined
    }

    preloadImage = (imageURL, onComplete) => {
      const cached = cache[imageURL];
      const preload = () => {
        const image = new Image();
        image.onload = () => {
          cache[imageURL] = image;
          onComplete(image);
        }
        image.src = imageURL;
      };

      if (!cached) {
        preload();
      } else {
        onComplete(cached);
      }
    }

    componentDidMount() {
      const {
        dispatch,
        imageURL
      } = this.props;

      dispatch(preloaderShow());

      this.preloadImage(imageURL, (image) => {
        this.setState({
          image,
          loaded: true
        });
        dispatch(preloaderHide());
      });
    }

    componentDidUpdate(prevProps) {
      const {
        imageURL,
        dispatch
      } = this.props;

      if (imageURL !== prevProps.imageURL) {
        dispatch(preloaderShow());
        this.preloadImage(imageURL, (image) => {
          this.setState({
            image
          });
          dispatch(preloaderHide());
        });
      }
    }

    render() {
      const {
        loaded,
        image
      } = this.state;

      const {
        dispatch,
        imageURL,
        ...rest
      } = this.props;

      if (loaded) {
        return <Child {...rest} image={image} />
      }

      return null;
    }
  }

  return connect()(ImagePreloader);
}
