import React from 'react';

export default function Private({ children }) {
  const visible = true;

  if (visible) {
    return children;
  }

  return null;
}
