import React, { Component } from 'react';
import uuid from 'uuid/v1';
import ParkingLotsEditor from '../ParkingLotsEditor/ParkingLotsEditor';
import {
  getCameras,
  getCameraShotUrls,
  getParkingLots,
  saveParkingLots,
  getParkingLotsInArea,
  saveCameraConstants,
  queueAnalyzer,
  invalidateCamera,
  getCamera
} from '../../api';
import CamerasMap from '../CamerasMap/CamerasMap';

import styles from './ParkingLotsEditorView.module.css';
import Button from '@material-ui/core/Button';
import { find } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Private from '../../../common/components/Private/Private';

const COLORS = [
  'rgb(244,11,0, 1.0)',
  'rgb(128,244,186, 1.0)',
  'rgb(207,40,227, 1.0)',
  'rgba(63, 81, 181,1.0)',
  'rgba(0, 188, 212,1.0)',
  'rgba(76, 175, 80,1.0)',
  'rgba(205, 220, 57,1.0)',
  'rgba(255, 152, 0,1.0)',
  'rgba(121, 85, 72,1.0)',
  'rgba(96, 125, 139,1.0)',
  'rgba(136, 14, 79,1.0)',
  'rgba(0, 96, 100,1.0)'
];

class ParkingLotsEditorView extends Component {
  state = {
    camera: undefined,
    cameras: [],
    parkingLots: [],
    nearbyParkingLots: [],
    shotUrls: [],
    imageSelected: 0,
    type: undefined,
    bbox: undefined,
    colorMode: false,
    editConstants: undefined
  };

  constructor(props) {
    super(props);
    this.mapEditorContainer = React.createRef();
  }

  componentDidMount() {
    getCameras().then(cameras => {
      this.setState({
        cameras
      });
    });
  }

  handleCamerasMapViewChange = async ({ bbox, zoom }) => {};

  handleCameraSelect = async camera => {
    this.setState({
      shotUrls: [],
      camera
    });

    const parkingLots = await getParkingLots(camera._id);
    const nearbyParkingLots = (await getParkingLotsInArea(camera._id)).filter(p => !find(parkingLots, ['_id', p._id]));
    const shotUrls = await getCameraShotUrls(camera._id);
    const { camera: cameraLast } = await getCamera(camera._id);

    this.setState({
      parkingLots,
      nearbyParkingLots,
      shotUrls,
      cameraLast: cameraLast
    });
  };

  handleClose = () => {
    this.setState({
      editConstants: undefined
    });
  };

  handleCameraAttrChange = () => {
    const {
      editConstants,
      camera: { _id: id }
    } = this.state;

    saveCameraConstants(id, editConstants)
      .then(() => {
        this.setState({
          camera: {
            ...this.state.camera,
            constants: editConstants
          },
          editConstants: undefined
        });
        alert('Сохранено');
      })
      .catch(e => {
        alert(`Ошибка ${e}`);
      });
  };

  handleConstantChange = (key, value) => {
    this.setState({
      editConstants: {
        ...this.state.editConstants,
        [key]: value
      }
    });
  };

  handleAttrChange = (lotId, attrName, attrValue) => {
    this.setState({
      parkingLots: this.state.parkingLots.map(pl => {
        if (pl._id !== lotId) {
          return pl;
        }

        return {
          ...pl,
          attributes: {
            ...pl.attributes,
            [attrName]: attrValue
          }
        };
      })
    });
  };

  handleCameraPolygonCreate = point => {
    const { parkingLots } = this.state;

    this.setState({
      parkingLots: parkingLots.concat({
        _id: `TMP_${uuid()}`,
        cameraPolygon: [point],
        mapPolygon: [],
        capacity: 1 // TODO: Editable
      })
    });
  };

  handleCameraPolygonPointAdd = point => {
    const { parkingLots } = this.state;

    this.setState({
      parkingLots: parkingLots.map((pl, idx) => {
        if (idx !== parkingLots.length - 1) {
          return pl;
        } else {
          return {
            ...pl,
            cameraPolygon: pl.cameraPolygon.concat(point)
          };
        }
      })
    });
  };

  handleCameraPolygonDelete = parkingLotId => {
    this.setState({
      parkingLots: this.state.parkingLots.filter(pl => pl._id !== parkingLotId)
    });
  };

  handleCameraPolygonPointChange = (parkingLotId, pointIndex, pointNew) => {
    const { parkingLots } = this.state;

    this.setState({
      parkingLots: parkingLots.map(pl => {
        if (parkingLotId !== pl._id) {
          return pl;
        } else {
          return {
            ...pl,
            cameraPolygon: pl.cameraPolygon.map((cp, idx) => {
              if (idx !== pointIndex) {
                return cp;
              } else {
                return pointNew;
              }
            })
          };
        }
      })
    });
  };

  handleMapPolygonEdit = (parkingLotId, mapPolygon) => {
    const { parkingLots } = this.state;

    this.setState({
      parkingLots: parkingLots.map(pl => {
        if (pl._id === parkingLotId) {
          return {
            ...pl,
            mapPolygon
          };
        }

        return pl;
      })
    });
  };

  handleMapPolygonAdd = mapPolygon => {
    const { parkingLots } = this.state;

    const last = parkingLots.findIndex(pl => pl.mapPolygon.length === 0);

    if (last === -1) return;

    this.setState({
      parkingLots: parkingLots.map((pl, idx) => {
        if (idx === last) {
          return {
            ...pl,
            mapPolygon
          };
        }

        return pl;
      })
    });
  };

  handleInvalidate = () => {
    const { camera } = this.state;

    invalidateCamera(camera._id)
      .then(() => {
        alert('Инвалидировано, повторно выберите камеру!');
      })
      .catch(() => alert('Ошибка'));
  };

  handleColor = () => {
    const { colorMode } = this.state;

    this.setState({
      colorMode: !colorMode
    });
  };

  handleAnalyzerClick = () => {
    const { camera } = this.state;

    queueAnalyzer(camera._id)
      .then(() => {
        alert('Запланировано!');
      })
      .catch(() => alert('Ошибка'));
  };

  handleSave = () => {
    const {
      camera: { _id },
      parkingLots
    } = this.state;

    saveParkingLots(_id, parkingLots)
      .then(parkingLots => {
        this.setState({
          parkingLots
        });
        alert('Сохранено');
      })
      .catch(() => alert('Ошибка'));
  };

  handleAttrsChangeClick = () => {
    const { camera } = this.state;

    this.setState({
      editConstants: {
        ...camera.constants
      }
    });
  };

  handleImageSelect = imageSelected => {
    this.setState({
      imageSelected
    });
  };

  render() {
    const {
      parkingLots,
      nearbyParkingLots,
      camera,
      cameras,
      shotUrls,
      colorMode,
      editConstants,
      cameraLast
    } = this.state;

    const isValid = parkingLots.every(pl => pl.mapPolygon.length > 0 && pl.cameraPolygon.length > 0);

    return (
      <div className={styles.root}>
        <div className={styles.maps}>
          <div>
            <strong>Выберите камеру</strong>
            <CamerasMap
              cameras={cameras}
              onCameraSelect={this.handleCameraSelect}
              onViewChanged={this.handleCamerasMapViewChange}
            />
          </div>
          <div>
            <strong>Разметка мест на карте</strong>
            <div ref={this.mapEditorContainer} />
          </div>
        </div>
        {camera && shotUrls.length > 0 && (
          <ParkingLotsEditor
            key={camera.cameraId}
            colors={colorMode ? COLORS : null}
            imgSelected={this.state.imageSelected}
            imgCount={this.state.shotUrls.length}
            onImageSelect={this.handleImageSelect}
            mapEditorContainer={this.mapEditorContainer.current}
            cameraImageURL={this.state.shotUrls[this.state.imageSelected]}
            cameraLocation={{ lat: camera.location.coordinates[1], lng: camera.location.coordinates[0] }}
            parkingLots={parkingLots}
            nearbyParkingLots={nearbyParkingLots}
            onCameraPolygonPointChange={this.handleCameraPolygonPointChange}
            onCameraPolygonPointAdd={this.handleCameraPolygonPointAdd}
            onCameraPolygonCreate={this.handleCameraPolygonCreate}
            onCameraPolygonDelete={this.handleCameraPolygonDelete}
            onMapPolygonAdd={this.handleMapPolygonAdd}
            onMapPolygonEdit={this.handleMapPolygonEdit}
            onAttrChange={this.handleAttrChange}
          />
        )}

        {camera && cameraLast && parkingLots.length >= 0 && (
          <div className={styles.actions}>
            <div style={{ fontSize: 14, marginBottom: 20 }}>{`Stats time: ${cameraLast.lastStatAnalyzeTime ||
              '-'}`}</div>
            <Button variant="contained" color="primary" onClick={this.handleSave} disabled={!isValid}>
              Сохранить
            </Button>
            <Private>
              <Button variant="contained" color="primary" onClick={this.handleAnalyzerClick}>
                Запланировать аналайзер
              </Button>
            </Private>
            <Private>
              <Button variant="contained" color="primary" onClick={this.handleAttrsChangeClick}>
                Изменить атрибуты
              </Button>
            </Private>

            <Button variant="contained" color="primary" onClick={this.handleColor}>
              Цветовая схема
            </Button>
            <Private>
              <Button variant="contained" color="primary" onClick={this.handleInvalidate}>
                Инвалидировать
              </Button>
            </Private>
          </div>
        )}

        <Dialog
          open={!!editConstants}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Изменение атрибутов</DialogTitle>
          <DialogContent>
            {editConstants &&
              Object.entries(editConstants).map(([key, value]) => (
                <div>
                  <label>{key}:</label>
                  {typeof value === 'boolean' ? (
                    <input type="checkbox" onChange={evt => this.handleConstantChange(key, !value)} checked={value} />
                  ) : (
                    <input value={value} onChange={evt => this.handleConstantChange(key, evt.target.value)} />
                  )}
                </div>
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Отмена
            </Button>
            <Button onClick={this.handleCameraAttrChange} color="primary" autoFocus>
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ParkingLotsEditorView.propTypes = {
  // cameras: PropTypes.arrayOf(PropTypes.shape({
  //   cameraId: PropTypes.string.isRequired,
  //   lat: PropTypes.number.isRequired,
  //   lng: PropTypes.number.isRequired
  // })).isRequired,
};
ParkingLotsEditorView.defaultProps = {};

export default ParkingLotsEditorView;
