import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { login } from '../api';

import TextField from '@material-ui/core/TextField';
import styles from './LoginView.module.css';

export default class LoginView extends Component {
  onLogin = () => {
    login(this.state.name, this.state.password).then(r => {
      const { token, user } = r.data;

      localStorage.setItem('token', JSON.stringify({ token, user }));
      window.location.href = '/';
    });
  };

  state = {
    name: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
      <div class={styles.main}>
        <div className={styles.box}>
          <TextField
            value={this.state.name}
            onChange={this.handleChange('name')}
            fullWidth
            id="outlined-name"
            label="Логин"
            margin="normal"
            variant="outlined"
          />
          <br />
          <TextField
            value={this.state.password}
            onChange={this.handleChange('password')}
            fullWidth
            id="outlined-name"
            label="Пароль"
            type="password"
            margin="normal"
            variant="outlined"
          />
          <Divider style={{ marginBottom: 20, marginTop: 20 }} />
          <Button variant="contained" color="primary" onClick={this.onLogin}>
            Войти
          </Button>
        </div>
      </div>
    );
  }
}
