import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class Portal extends Component {
  render() {
    if (this.props.container) {
      console.log('Portal created');
      return ReactDOM.createPortal(
        this.props.children,
        this.props.container,
      );
    }

    return null;
  }
}

Portal.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired
};
Portal.defaultProps = {};

export default Portal;
