export function getMousePos(canvas, evt) {
    const rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
}

export function createPolygon(start, end, closed) {
  const polygon = [
    { x: end.x, y: start.y },
    end,
    { x: start.x, y: end.y }
  ];

  return closed ? polygon : [start, ...polygon];
}

export function getRectArea(start, end) {
  return (end.x - start.x) * (end.y - start.y);
}
