import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './GMapEditor.module.css';
import withMap from '../Map/Map';
import drawPluginOptions from './drawPluginOptions';
import classnames from 'classnames';

const CIRCLE_RADIUS = 1;
const options = { color: '#ff7800', weight: 0.8, draggable: true, fillOpacity: 0.4, radius: CIRCLE_RADIUS };

const getColor = (colors, idx) => {
  if (colors) {
    const n = colors.length;
    const color = colors[((idx % n) + n) % n];
    return color || '#ff7800';
  }

  return '#ff7800';
};

function fixMousePosition(DG) {
  DG.DomEvent.getMousePosition = function(t, e) {
    if (!e) return new DG.Point(t.clientX / 2.3, t.clientY / 2.3);

    const transform = getComputedStyle(e).transform;
    let scale = 1;

    if (transform.indexOf('matrix') >= 0) {
      let values = transform.split('(')[1];
      values = values.split(')')[0];
      values = values.split(',');

      const a = values[0];
      const b = values[1];

      scale = Math.sqrt(a * a + b * b);
    }

    const i = e.getBoundingClientRect();
    return new DG.Point((t.clientX - i.left - e.clientLeft) / scale, (t.clientY / 1 - i.top - e.clientTop) / scale);
  };
}

class GMapEditor extends Component {
  state = {
    clickPolygonMode: undefined
  };

  componentDidMount() {
    const {
      DG,
      center: { lat, lng },
      onMapPolygonAdd,
      onMapPolygonEdit,
      colors
    } = this.props;

    fixMousePosition(DG);

    this.map = DG.map('GMapEditor_map', {
      center: DG.latLng(lat, lng),
      zoom: 18
    });

    const icon = DG.icon({
      iconUrl: 'https://maps.api.2gis.ru/2.0/img/DGCustomization__marker.png',
      iconSize: [11, 17]
    });

    DG.marker([lat, lng], { icon }).addTo(this.map);

    const editableLayers = new DG.FeatureGroup();
    const nearbyLayer = new DG.FeatureGroup();
    this.editableLayers = editableLayers;
    this.nearbyLayer = nearbyLayer;
    this.map.addLayer(this.editableLayers);
    this.map.addLayer(nearbyLayer);

    this.props.parkingLots
      .filter(p => p.mapPolygon.length > 0)
      .forEach(({ mapPolygon }, idx) => {
        const p =
          mapPolygon.length === 1
            ? DG.circle([mapPolygon[0].lat, mapPolygon[0].lng], {
                color: getColor(colors, idx),
                weight: 0.5,
                radius: CIRCLE_RADIUS,
                draggable: true
              })
            : DG.polygon(mapPolygon.map(p => [p.lat, p.lng]), { color: '#ff7800', weight: 0.5, draggable: true });

        p.addTo(editableLayers);

        const that = this;

        p.on('dragend', () => {
          const index = Object.keys(that.editableLayers._layers).findIndex(l => l == p._leaflet_id);
          const parkingLotId = that.props.parkingLots[index]._id;

          onMapPolygonEdit(parkingLotId, p.getLatLngs ? p.getLatLngs()[0] : [p.getLatLng()]);
        });
      });

    this.props.nearbyParkingLots
      .filter(p => p.mapPolygon.length > 0)
      .forEach(({ mapPolygon }) => {
        const p =
          mapPolygon.length === 1
            ? DG.circle([mapPolygon[0].lat, mapPolygon[0].lng], {
                color: '#5d5d5d',
                weight: 0.5,
                radius: CIRCLE_RADIUS,
                draggable: false
              })
            : DG.polygon(mapPolygon.map(p => [p.lat, p.lng]), { color: '#5d5d5d', weight: 0.5, draggable: false });

        p.addTo(nearbyLayer);
      });

    const drawControl = new DG.Control.Draw({
      ...drawPluginOptions,
      edit: {
        featureGroup: this.editableLayers,
        remove: false
      }
    });

    this.map.addControl(drawControl);
    this.map.on('draw:created', function(e) {
      this.editableLayers.addLayer(e.layer);
      onMapPolygonAdd(e.layer.getLatLngs()[0]);
    });
    this.map.on('draw:edited', e => {
      Object.keys(e.layers._layers).forEach(updatedId => {
        const index = Object.keys(this.editableLayers._layers).findIndex(l => l === updatedId);
        const updatedLayer = e.layers._layers[updatedId];
        const parkingLotId = this.props.parkingLots[index]._id;

        onMapPolygonEdit(parkingLotId, updatedLayer.getLatLngs()[0]);
      });
    });

    this.map.on('click', ({ latlng: { lat, lng } }) => {
      if (this.state.clickPolygonMode) {
        const obj = DG.circle([lat, lng], { ...options }).addTo(this.editableLayers);
        const that = this;

        obj.on('dragend', () => {
          const index = Object.keys(that.editableLayers._layers).findIndex(l => l == obj._leaflet_id);
          const parkingLotId = that.props.parkingLots[index]._id;

          onMapPolygonEdit(parkingLotId, obj.getLatLngs ? obj.getLatLngs()[0] : [obj.getLatLng()]);
        });

        onMapPolygonAdd([{ lat, lng }]);
      }
    });
  }

  handleClickPolygonModeChange = () => {
    this.setState({
      clickPolygonMode: !this.state.clickPolygonMode
    });
  };

  drawParkingLots = (parkingLots, nearbyParkingLots) => {
    const that = this;
    const { onMapPolygonEdit, DG, colors } = this.props;

    // clear
    Object.keys(this.editableLayers._layers).forEach(k =>
      this.editableLayers.removeLayer(this.editableLayers._layers[k])
    );

    parkingLots
      .filter(lot => lot.mapPolygon.length > 0)
      .forEach(({ mapPolygon }, idx) => {
        const obj =
          mapPolygon.length === 1
            ? DG.circle([mapPolygon[0].lat, mapPolygon[0].lng], { ...options, color: getColor(colors, idx) }).addTo(
                this.editableLayers
              )
            : DG.polygon(mapPolygon.map(p => [p.lat, p.lng]), { ...options }).addTo(this.editableLayers);

        obj.on('dragend', () => {
          const index = Object.keys(that.editableLayers._layers).findIndex(l => l == obj._leaflet_id);
          const parkingLotId = that.props.parkingLots[index]._id;

          onMapPolygonEdit(parkingLotId, obj.getLatLngs ? obj.getLatLngs()[0] : [obj.getLatLng()]);
        });
      });

    nearbyParkingLots
      .filter(p => p.mapPolygon.length > 0)
      .forEach(({ mapPolygon }) => {
        const p =
          mapPolygon.length === 1
            ? DG.circle([mapPolygon[0].lat, mapPolygon[0].lng], {
                color: '#5d5d5d',
                weight: 0.5,
                radius: CIRCLE_RADIUS,
                draggable: false
              })
            : DG.polygon(mapPolygon.map(p => [p.lat, p.lng]), { color: '#5d5d5d', weight: 0.5, draggable: false });

        p.addTo(this.nearbyLayer);
      });

  };

  componentDidUpdate(prevProps) {
    const { parkingLots, nearbyParkingLots, colors } = this.props;

    if (parkingLots.length !== prevProps.parkingLots.length || colors !== prevProps.colors) {
      this.drawParkingLots(parkingLots, nearbyParkingLots);
    }
  }

  render() {
    const { clickPolygonMode } = this.state;
    return (
      <div className={styles.root}>
        <div className={styles.ctrls}>
          <div
            className={classnames(styles.ctrl, { [styles.selected]: !!clickPolygonMode })}
            title="Создать место"
            onClick={this.handleClickPolygonModeChange}
          >
            <i className="fas fa-circle" />
          </div>
        </div>
        <div id="GMapEditor_map" className={styles.map} />
      </div>
    );
  }
}

GMapEditor.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  nearbyParkingLots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      mapPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  parkingLots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      mapPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  onMapPolygonAdd: PropTypes.func.isRequired,
  onMapPolygonEdit: PropTypes.func.isRequired
};
GMapEditor.defaultProps = {};

export default withMap(GMapEditor);
