import axios from 'axios';
import store from '../redux/index';
import { preloaderHide, preloaderShow } from '../common/redux/preloader';

const axiosInst = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosInst.interceptors.request.use(
  function(config) {
    store.dispatch(preloaderShow());
    return config;
  },
  function(error) {
    store.dispatch(preloaderHide());
    return Promise.reject(error);
  }
);

axiosInst.interceptors.response.use(
  function(response) {
    store.dispatch(preloaderHide());
    return response;
  },
  function(error) {
    store.dispatch(preloaderHide());

    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default axiosInst;
