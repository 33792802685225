import React, { Component } from 'react';

import styles from './DebugView.module.css';
import CamerasMap from '../../editor/components/CamerasMap/CamerasMap';
import { getCameras, getCamera, saveShot, switchSaveShotState } from '../../editor/api';
import CameraImageLots from '../CameraImageLots/CameraImageLots';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Private from '../../common/components/Private/Private';

class DebugView extends Component {
  state = {
    cameras: [],
    debugCamera: undefined,
    lastShotUrl: undefined,
    inProgress: false,
    saveShotAvailable: false
  };

  componentDidMount() {
    getCameras().then(cameras => {
      this.setState({
        cameras
      });
    });

    this.refreshInterval = setInterval(() => {
      const { debugCamera = {} } = this.state;

      if (debugCamera.saveShot) {
        this.handleCameraSelect(debugCamera).then(r => console.log(`Camera ${debugCamera._id} was updated`));
      }
    }, 20000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  handleSaveShot = () => {
    // const {
    //   debugCamera: {
    //     geohash,
    //     cameraId
    //   }
    // } = this.state;
    //
    // const key = { geohash, cameraId };
    //
    // this.setState({
    //   saveShotAvailable: false
    // });
    //
    // saveShot(key).catch(e => {
    //   alert('Ошибка');
    // });
  };

  handleSaveShotSwitch = (event, checked) => {
    const { debugCamera } = this.state;

    switchSaveShotState(debugCamera._id, checked)
      .then(() => {
        this.setState({
          debugCamera: {
            ...debugCamera,
            saveShot: checked
          }
        });
      })
      .catch(e => {
        alert('Ошибка');
      });
  };

  handleCameraSelect = async selectedCameras => {
    const { camera, lots } = await getCamera(selectedCameras._id);

    this.setState({
      debugCamera: {
        ...camera,
        lots
      },
      saveShotAvailable: true,
      lastShotUrl: camera.lastShotUrl
    });
  };

  handleCamerasMapViewChange = async ({ bbox, zoom }) => {};

  render() {
    const { lastShotUrl, debugCamera, inProgress, cameras } = this.state;
    return (
      <div className={styles.root}>
        <div className={styles.actions}>
          {/*<Fab color="primary" disabled={!debugCamera || inProgress || true} onClick={this.handleSaveShot}>*/}
          {/*  <Icon className="fa fa-save" />*/}
          {/*</Fab>*/}
          {debugCamera && (
            <>
              <div className={styles.cameraPlacesStatus}>
                <div>
                  Всего мест: <span>{debugCamera.lots.length}</span>
                </div>
                <div>
                  Мест свободно: <span>{debugCamera.lots.filter(l => l.freeLots > 0).length}</span>
                </div>
                <div>
                  Мест занято: <span>{debugCamera.lots.filter(l => l.freeLots === 0).length}</span>
                </div>
                <div>
                  Мест не мониторится: <span>{debugCamera.lots.filter(l => l.freeLots === -1).length}</span>
                </div>
              </div>
              <Private>
                <div
                  style={{
                    fontSize: 14,
                    marginBottom: 20
                  }}
                >{`Stats time: ${debugCamera.lastStatAnalyzeTime || '-'}`}</div>
              </Private>
              <FormControlLabel
                control={
                  <Checkbox checked={!!debugCamera.saveShot} onChange={this.handleSaveShotSwitch} color="primary" />
                }
                labelPlacement="bottom"
                label="Отладка следующего кадра"
              />
            </>
          )}
        </div>
        <div className={styles.camerasWrapper}>
          <CamerasMap
            cameras={cameras}
            onCameraSelect={this.handleCameraSelect}
            onViewChanged={this.handleCamerasMapViewChange}
          />
        </div>
        <div>
          {lastShotUrl && debugCamera && (
            <CameraImageLots
              imageURL={lastShotUrl}
              parkingLots={debugCamera.lots}
              lastDetectionBoxes={debugCamera.lastDetectionBoxes}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DebugView;
