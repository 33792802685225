import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authenticated } from './redux/auth';
import Preloader from '../common/components/Preloader/Preloader';

const Callback = ({ history, auth, dispatch }) => {
  // auth.handleAuthentication().then(({ idToken, expiresAt }) => {
  //   dispatch(authenticated(idToken, expiresAt));
  //   history.push('/');
  // });

  return <Preloader forceShow />;
};

export default connect()(withRouter(Callback));
