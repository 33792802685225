/* global DG */
import React, { Component } from 'react';

export default function withMap(Child, module) {
  return class Map extends Component {
    state = {
      loaded: false
    }

    componentDidMount() {
      DG.then(() => {
        if (module) {
          return DG.plugin(module);
        }
        return Promise.resolve()
      }).then(() => {
        this.setState({
          loaded: true
        });
      })
    }

    render() {
      const {
        loaded
      } = this.state;

      return (
        <div>
          {
            loaded && (
              <Child DG={DG} { ...this.props } />
            )
          }
        </div>
      );
    }
  }
}
