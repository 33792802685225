import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { find } from 'lodash';
import { createPolygon, getMousePos, getRectArea } from './utils';

import styles from './CImageMapper.module.css';
import ImagePreloader from '../../../common/components/ImagePreloader/ImagePreloader';
import inside from 'point-in-polygon';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Private from '../../../common/components/Private/Private';
import { isSuperAdmin } from '../../../common/utils';

const MODE_CURSOR = 'MODE_CURSOR';
const MODE_POLYGON = 'MODE_POLYGON';
const MODE_RECTANGLE = 'MODE_RECTANGLE';
const MODE_TRASH = 'MODE_TRASH';
const MODE_ATTRS = 'MODE_ATTRS';

const POINT_WIDTH = 3;
const MIN_RECT_AREA = 200;
const POINT_COLOR = 'rgba(220, 20, 60, 0.4)';
const POL_COLOR = 'rgba(0, 191, 255, 0.35)';
const MOUSE_LINE_COLOR = 'rgba(255, 0, 0, 0.65)';

const getColor = (colors, defaultColor, idx) => {
  if (colors) {
    const n = colors.length;
    const color = colors[((idx % n) + n) % n];
    return (color || defaultColor).replace(/[^,]+(?=\))/, '0.65');
  }

  return defaultColor;
};

const allAttrs = {
  daytime: 'Только днем',
  handicap: 'Место для инвалидов',
  corrupted: 'Статус не известен',
  markup: 'Разметка на асфальте'
};

const allowedAttrs = () => {
  return Object.keys(allAttrs).filter(a =>
    ['handicap', 'markup', ...(isSuperAdmin() ? ['daytime', 'corrupted'] : [])].includes(a)
  );
};

class CImageMapper extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();

    this.state = {
      mode: MODE_CURSOR,
      vLineX: 0,
      hLineY: 0
    };
  }

  handleAttrsClose = () => this.setState({ selectedParkingLotId: undefined });

  handleCanvasMouseMove = evt => {
    const { editingPoint, mode, rectEndPoint } = this.state;
    const { onCameraPolygonPointChange, readOnly } = this.props;

    if (readOnly) return;

    const canvas = this.canvas.current;
    const { x, y } = getMousePos(canvas, evt);

    this.setState({
      vLineX: x,
      hLineY: y
    });

    if (mode === MODE_CURSOR) {
      if (editingPoint) {
        onCameraPolygonPointChange(editingPoint._id, editingPoint.pointIndex, { x, y });
      }
    } else if (mode === MODE_RECTANGLE && rectEndPoint) {
      this.setState({
        rectEndPoint: { x, y }
      });
    }
  };

  handleCanvasClick = evt => {
    const {
      onCameraPolygonCreate,
      onCameraPolygonPointAdd,
      onCameraPolygonDelete,
      readOnly,
      parkingLots,
      onParkingLotClick
    } = this.props;

    const { mode, initialClick } = this.state;

    const canvas = this.canvas.current;

    if (mode === MODE_CURSOR) {
      const { x, y } = getMousePos(canvas, evt);

      const pl = parkingLots.find(pl => inside([x, y], pl.cameraPolygon.map(cp => [cp.x, cp.y])));

      if (pl) {
        onParkingLotClick(pl);
      }
    }

    if (readOnly) return;

    if (mode === MODE_POLYGON) {
      const { x, y } = getMousePos(canvas, evt);

      if (initialClick) {
        onCameraPolygonCreate({ x, y });
        this.setState({
          initialClick: false
        });
      } else {
        onCameraPolygonPointAdd({ x, y });
      }
    } else if (mode === MODE_TRASH) {
      const { x, y } = getMousePos(canvas, evt);
      const plToDelete = parkingLots.find(pl => inside([x, y], pl.cameraPolygon.map(cp => [cp.x, cp.y])));

      if (plToDelete) {
        onCameraPolygonDelete(plToDelete._id);
      }
    } else if (mode === MODE_ATTRS) {
      const { x, y } = getMousePos(canvas, evt);
      const selectedParkingLot = parkingLots.find(pl => inside([x, y], pl.cameraPolygon.map(cp => [cp.x, cp.y])));

      if (selectedParkingLot) {
        this.setState({
          selectedParkingLotId: selectedParkingLot._id
        });
      }
    }
  };

  handleCanvasMouseDown = evt => {
    const { mode } = this.state;
    const { readOnly } = this.props;

    if (readOnly) return;

    const canvas = this.canvas.current;
    const { x, y } = getMousePos(canvas, evt);

    if (mode === MODE_CURSOR) {
      this.setState({
        editingPoint: this.getEditingPoint(x, y)
      });
    } else if (mode === MODE_RECTANGLE) {
      this.setState({
        rectStartPoint: { x, y },
        rectEndPoint: { x, y }
      });
    }
  };

  handleCanvasMouseUp = () => {
    const { mode, rectStartPoint, rectEndPoint } = this.state;
    const { onCameraPolygonCreate, onCameraPolygonPointAdd, readOnly } = this.props;

    if (readOnly) return;

    if (mode === MODE_CURSOR) {
      this.setState({
        editingPoint: undefined
      });
    } else if (mode === MODE_RECTANGLE && rectEndPoint) {
      this.setState({
        rectStartPoint: undefined,
        rectEndPoint: undefined
      });
      if (getRectArea(rectStartPoint, rectEndPoint) >= MIN_RECT_AREA) {
        onCameraPolygonCreate(rectStartPoint);
        setTimeout(() => {
          createPolygon(rectStartPoint, rectEndPoint, true).forEach(onCameraPolygonPointAdd);
        }, 0);
      }
    }
  };

  getEditingPoint = (x, y) => {
    const { parkingLots } = this.props;

    const crossPoint = p => {
      const left = p.x - POINT_WIDTH;
      const right = p.x + POINT_WIDTH;
      const top = p.y - POINT_WIDTH;
      const bottom = p.y + POINT_WIDTH;

      return x >= left && x <= right && y >= top && y <= bottom;
    };

    for (let i = 0; i < parkingLots.length; i++) {
      const pointIndex = parkingLots[i].cameraPolygon.findIndex(crossPoint);

      if (pointIndex !== -1) {
        return {
          _id: parkingLots[i]._id,
          pointIndex
        };
      }
    }
  };

  drawPoints = () => {
    this.ctx.fillStyle = POINT_COLOR;

    const { parkingLots } = this.props;

    parkingLots.forEach(({ cameraPolygon }) => {
      cameraPolygon.forEach(({ x, y }) => {
        this.ctx.beginPath();
        this.ctx.rect(x - POINT_WIDTH, y - POINT_WIDTH, POINT_WIDTH * 2, POINT_WIDTH * 2);
        this.ctx.fill();
      });
    });
  };

  drawLines = (width, height) => {
    const { vLineX, hLineY } = this.state;

    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = MOUSE_LINE_COLOR;
    this.ctx.beginPath();
    this.ctx.moveTo(vLineX, 0);
    this.ctx.lineTo(vLineX, height);
    this.ctx.moveTo(0, hLineY);
    this.ctx.lineTo(width, hLineY);
    this.ctx.closePath();
    this.ctx.stroke();
  };

  drawPolygons = () => {
    let { parkingLots, colors } = this.props;
    const { rectStartPoint, rectEndPoint } = this.state;

    const draw = polygon => {
      polygon.forEach(({ x, y }, i) => {
        if (i === 0) {
          this.ctx.moveTo(x, y);
        }
        this.ctx.lineTo(x, y);
      });
    };

    parkingLots.forEach(({ cameraPolygon, color = POL_COLOR }, idx) => {
      this.ctx.fillStyle = getColor(colors, color, idx);
      this.ctx.beginPath();
      draw(cameraPolygon);
      this.ctx.fill();
    });

    this.ctx.fillStyle = POL_COLOR;

    if (rectStartPoint) {
      this.ctx.beginPath();
      draw(createPolygon(rectStartPoint, rectEndPoint));
      this.ctx.fill();
    }
  };

  draw = () => {
    const { readOnly, drawAdditional, image, drawLots } = this.props;

    if (!image) {
      return;
    }

    this.ctx.clearRect(0, 0, image.width, image.height);
    this.ctx.drawImage(image, 0, 0, image.width, image.height);

    if (drawLots) {
      this.drawPolygons();
    }

    if (!readOnly) {
      this.drawPoints();
      this.drawLines(image.width, image.height);
    }
    drawAdditional(this.ctx);
  };

  componentDidMount() {
    this.ctx = this.canvas.current.getContext('2d');
    this.draw();
  }

  componentDidUpdate() {
    this.draw();
  }

  handleModeChange = mode => this.setState({ mode, initialClick: true });

  render() {
    const { mode, selectedParkingLotId } = this.state;

    const { readOnly, onAttrChange, parkingLots, imgCount, imgSelected, onImageSelect, image } = this.props;

    const selectedParkingLot = find(parkingLots, ['_id', selectedParkingLotId]);
    const attributes = selectedParkingLot ? selectedParkingLot.attributes || {} : {};

    return (
      <div className={styles.root}>
        <div className={classnames(styles.wrapper, { [styles.editing]: mode === MODE_POLYGON })}>
          <canvas
            id="CImageMapper"
            width={image.width}
            height={image.height}
            ref={this.canvas}
            onMouseMove={this.handleCanvasMouseMove}
            onClick={this.handleCanvasClick}
            onMouseDown={this.handleCanvasMouseDown}
            onMouseUp={this.handleCanvasMouseUp}
          />
          {!readOnly && (
            <div className={styles.control} title="Курсор">
              <Private>
                <div
                  className={classnames(styles.controlItem, { [styles.selected]: mode === MODE_CURSOR })}
                  onClick={() => this.handleModeChange(MODE_CURSOR)}
                >
                  <i className="fas fa-mouse-pointer" />
                </div>
              </Private>
              <Private>
                <div
                  className={classnames(styles.controlItem, { [styles.selected]: mode === MODE_POLYGON })}
                  onClick={() => this.handleModeChange(MODE_POLYGON)}
                  title="Создать полигон"
                >
                  <i className="fas fa-draw-polygon" />
                </div>
              </Private>
              <Private>
                <div
                  className={classnames(styles.controlItem, { [styles.selected]: mode === MODE_RECTANGLE })}
                  onClick={() => this.handleModeChange(MODE_RECTANGLE)}
                  title="Создать прямоугольник"
                >
                  <i className="fas fa-window-maximize" />
                </div>
              </Private>
              <div
                className={classnames(styles.controlItem, { [styles.selected]: mode === MODE_TRASH })}
                onClick={() => this.handleModeChange(MODE_TRASH)}
                title="Удалить место"
              >
                <i className="fas fa-trash" />
              </div>
              <div
                className={classnames(styles.controlItem, { [styles.selected]: mode === MODE_ATTRS })}
                onClick={() => this.handleModeChange(MODE_ATTRS)}
                title="Изменить атрибуты"
              >
                <i className="fa fa-table" />
              </div>
            </div>
          )}
          {imgCount && (
            <div className={styles.imagesSwitch}>
              {Array.from(Array(imgCount).keys()).map(e => (
                <div
                  key={e}
                  onClick={() => onImageSelect(e)}
                  className={classnames(styles.imagesSwitchBall, { [styles.selected]: imgSelected === e })}
                />
              ))}
            </div>
          )}
        </div>
        <Dialog open={!!this.state.selectedParkingLotId} onClose={this.handleAttrsClose}>
          <div className={styles.attrsWrapper}>
            {allowedAttrs().map(k => (
              <div key={k}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={attributes[k]}
                      onChange={() => onAttrChange(selectedParkingLotId, k, !attributes[k])}
                    />
                  }
                  label={allAttrs[k]}
                />
              </div>
            ))}
          </div>
        </Dialog>
      </div>
    );
  }
}

CImageMapper.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  drawAdditional: PropTypes.func,
  drawLots: PropTypes.bool,
  image: PropTypes.instanceOf(Image).isRequired,
  imgCount: PropTypes.number,
  imgSelected: PropTypes.number,
  parkingLots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      attributes: PropTypes.object.isRequired,
      color: PropTypes.string,
      cameraPolygon: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        })
      ).isRequired,
      procLog: PropTypes.string
    })
  ).isRequired,
  onCameraPolygonCreate: PropTypes.func,
  onCameraPolygonPointAdd: PropTypes.func,
  onCameraPolygonPointChange: PropTypes.func,
  onCameraPolygonDelete: PropTypes.func,
  onAttrChange: PropTypes.func,
  onImageSelect: PropTypes.func,
  onParkingLotClick: PropTypes.func
};
CImageMapper.defaultProps = {
  readOnly: false,
  drawLots: true,
  drawAdditional: () => {},
  onParkingLotClick: () => {}
};

export default ImagePreloader(CImageMapper);
