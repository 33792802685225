const AUTHENTICATED = 'AUTH_AUTHENTICATED';
const UNAUTHENTICATED = 'AUTH_UNAUTHENTICATED';

export function authenticated(token, user) {
  return {
    type: AUTHENTICATED,
    token,
    user
  };
}

export function unAuthenticated() {
  return {
    type: UNAUTHENTICATED
  };
}

export default function reducer(state = { authenticated: false }, action = {}) {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        token: action.token,
        user: action.user
      };
    case UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        token: undefined,
        user: undefined
      };
    default:
      return state;
  }
}
