import axios from '../../net/axios';

const CAMERAS_LOADED = 'CAMERAS_LOADED';
const CAMERA_LOADED = 'CAMERA_LOADED';

export function loadCamera(cameraId) {
  return async (dispatch, getState) => {
    const {
      auth: {
        idToken
      }
    } = getState();

    const { data: camera } = await axios.get(`/admin/cameras/${cameraId}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });

    return dispatch(cameraLoaded(camera));
  }
}

export function loadCameras() {
  return async (dispatch, getState) => {
    const {
      auth: {
        idToken
      },
      cameras: {
        loaded,
        cameras
      }
    } = getState();

    if (!loaded) {
      const { data } = await axios.get('/admin/cameras/all', {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return dispatch(camerasLoaded(data));
    }

    return Promise.resolve(cameras);
  }
}

export function camerasLoaded(cameras) {
  return ({
    type: CAMERAS_LOADED,
    cameras
  });
}

export function cameraLoaded(camera) {
  return ({
    type: CAMERA_LOADED,
    camera
  });
}

export default function reducer(state = { loaded: false, cameras: [] }, action = {}) {
  switch (action.type) {
    case CAMERAS_LOADED:
      return {
        ...state,
        loaded: true,
        cameras: action.cameras
      };
    case CAMERA_LOADED:
      return {
        ...state,
        cameras: state.cameras.map(camera => {
          if (camera.cameraId !== action.camera.cameraId) {
            return camera;
          }

          return action.camera;
        })
      };
    default:
      return state;
  }
}

