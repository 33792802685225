export default {
  position: 'topright',
  draw: {
    polygon: {
      allowIntersection: false,
      showArea: true,
      drawError: {
        color: '#b00b00',
        timeout: 1000
      },
      shapeOptions: {
        color: "#ff7800",
        weight: 1,
      }
    },
    rectangle: {
      allowIntersection: false,
      showArea: true,
      drawError: {
        color: '#b00b00',
        timeout: 1000
      },
      shapeOptions: {
        color: "#ff7800",
        weight: 1,
      }
    },
    polyline: false,
    circlemarker: false,
    marker: false
  }
};
